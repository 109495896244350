.footer {
    width: 100%;
    height: 60vh;
    background: linear-gradient(0deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .3)), url('../../assets/gradiant10_06.jpg') no-repeat center center / cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-content {
    display: grid;
    grid-template-columns: repeat(4, auto);
    justify-content: center;
    align-items: center;
    gap: 4rem;
    margin-top: 2rem;
}
.inner-footer-menu {
    display: flex;
    flex-direction: row;
    gap: 4rem;
    text-align: left;
}

.inner-footer-menu ul {
    list-style-type: none;
}

.inner-footer-menu h3 {
    color: var(--primary-light);
    text-transform: uppercase;
    padding-bottom: .7rem;
    font-weight: normal;
}
.inner-footer-menu li {
    color: var(--primary-light);
    padding-bottom: .2rem;
}
.footer-social-media {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-bottom: 1px solid var(--primary-light);
    padding: .4rem 1.4rem;
    border-radius: 7px;
}
.facebook a, .instagram a {
    font-size: 1.4rem;
    color: var(--primary-light);
}
.facebook a:hover, .instagram a:hover {
    color: var(--primary-hover)
}

.footer-logo img {
    width: 150px;
}

.rights {
    margin-top: 4rem;
}

.rights p {
    padding: 2rem;
    color: var(--primary-light);
    font-size: .9rem;
}

@media screen and (max-width: 940px) {
    .footer {
        height: 100%;
    }
    .footer-content {
        grid-template-columns: auto;
    }
    .footer-social-media {
        border-bottom: none;
    }
}

@media screen and (max-width: 678px) {
    .footer-content {
        gap: 2rem;
    }
    .inner-footer-menu {
        flex-direction: column;
        text-align: center;
    }
}
