.clients-stc {
    margin-top: 3rem;
}
.clients-stc h1 {
    color: var(--primary-blue);
    padding-bottom: 20px;
}

.clients-stc p {
    color: var(--primary-grey);
}
.client-content img {
    width: 250px;
}

.client-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

@media screen and (max-width: 940px) {
    .client-content img {
        width: 120px;
    }
    .clients-stc h1 {
        font-size: 2.4rem;
        padding-bottom: 20px;
    }
    .client-content {
        gap: 1rem;
    }
}

@media screen and (max-width: 678px) {
    .client-content img {
        width: 60px;
    }
}

