.service {
    width: 100%;
    height: 85vh;
    background: url('../../../assets/gradiant10_06.jpg') no-repeat center center / cover;
    border-radius: 50% 50% 0 0;
    padding: 20px;
}

.service-h1 {
    color: var(--primary-light);
    font-weight: 200;
    padding: 3rem 0 1rem 0;
}

.service-p {
    color: var(--primary-light);
    font-weight: 200;
}

.service-content {
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: center;
    align-items: center;
    text-align-last: left;
    gap: 12px;
}

.service-card {
    margin-top: 12px;
    box-shadow: 0 0 5px var(--primary-blue);
    border-radius: 7px;
    background-color: #fff;
}

.service-card img {
    width: 250px;
    height: 130PX;
    object-fit: cover;
    border-radius: 7px 7px 0 0;
}

.service-details {
    padding: 16px;
}

@media screen and (max-width: 940px) {
    .service {
        height: 100%;
        border-radius: 0 0 0 0;
    }
    .service-content {
        grid-template-columns: auto;
        gap: 10px;
    }
    .service-h1 {
        font-size: 2.4rem;
        padding-bottom: 20px;
    }
}