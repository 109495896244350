.about  {
    width: 100%;
    height: 75vh;
    background-color: var(--primary-light);
    border-radius: 0 100% 0 50%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.about-info {
    text-align-last: left;
}
.about-info h1 {
    color: var(--primary-blue);
    padding-bottom: 20px;
}

.about-info p {
    color: var(--primary-grey);
}

.about-img img {
    width: 600px;
}

@media screen and (max-width: 940px) {
    .about {
        height: 100%;
        border-radius: 0 90% 0 25%;
    }
    .about-content {
        flex-direction: column-reverse;
    }
    .about-img img {
        width: 250px;
    }
    .about-info p {
        font-size: 1rem;
    }
}