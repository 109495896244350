.contact {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0deg, rgba(0,0,0,.5), rgba(0,0,0, .7)), url('../../../assets/bg-c02.png') no-repeat center center / cover;
}

.contact .container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 500px;
    height: 250px;
    padding: 1rem;
    border-radius: .4rem;
    box-shadow: 0 2px 15px 0 var(--light-white);
}
.form-input {
    width: 90%;
    height: 2rem;
    border-radius: .4rem;
    border: 1px solid var(--primary-grey);
}

.btn-form {
    color: var(--light-white);
    font-size: 1.18rem;
    width: 90%;
    background-color: var(--primary-blue);
    opacity: .85;
    transition: all .5s ease-in-out;
    cursor: pointer;
}
.btn-form:hover {
    border: 1px solid var(--light-white);
    color: var(--light-white);
}
input[type="text"], input[type="email"] {
    padding: 0.4rem;
}

textarea {
    padding: 0.4rem;
    width: 90%;
    height: 85px;
    resize: none;
    border-radius: .4rem;
}