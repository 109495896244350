.team {
    width: 100%;
    padding: 3rem;
}

.team h1 {
    color: var(--primary-blue);
    padding-bottom: 20px;
}
.team p {
    color: var(--primary-grey);
    padding-bottom: 20px;
}

.team-details p {
    font-size: .85rem;
}
.team-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.team-card img {
    width: 250px;
    height: 250px;
    object-fit: cover;
    border-radius: 7px 7px 0 0;
}

.team-card {
    width: 400px;
    width: 500px;
    box-shadow: 0 0 5px var(--primary-grey);
    border-radius: 7px 7px 0 0;
}
.team-details {
    padding: 1rem;
}
.team-details h3 {
    padding-bottom: .7rem;
    font-size: 1rem;
    color: var(--primary-blue);
}

@media screen and (max-width: 940px) {
    .team-content {
        flex-direction: column;
    }
    .team h1 {
        font-size: 2.4rem;
        padding-bottom: 10px;
    }
    .team-card {
        width: 300px;
    }
}