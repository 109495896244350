.container-map {
  width: 100%;
  height: 60vh;
}
.mapouter{
  position:relative;
  text-align:right;
  width:100%;
  height:510px;
  margin-bottom: 30px;
}
.gmap_canvas {
  overflow:hidden;
  background:none!important;
  width:100%;
  height:510px;
}
.gmap_iframe {
  height:510px!important;
}