.company {
    width: 100%;
    height: 65vh;
    margin: 3rem auto;
}

.company h1 {
    font-size: 3rem;
    color: var(--primary-blue);
}

.company-content {
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: center;
    align-items: center;
    gap: 1.4rem;
    margin: 3rem auto;
}

.company-image img {
    width: 400px;
}

.company-details {
    text-align: left;
    padding: 0.4rem;
}
.company-details h3 {
    font-size: 1.3rem;
    padding-bottom: 0.7rem;
    color: var(--primary-blue);
    font-weight: normal;
}
.company-details p {
    font-size: 1.1rem;
    color: var(--primary-grey);
}

@media screen and (max-width: 940px) {
    .company {
        height: 100%;
    }
    .company h1 {
        font-size: 3rem;
    }
    .company-content {
        grid-template-columns: auto;
    }
    .company-image img {
        width: 300px;
    }
    .company-details {
        text-align: center;
    }
}

@media screen and (max-width: 678px) {
    .company h1 {
        font-size: 2.4rem;
    }
    .company-image img {
        width: 200px;
    }
}