.landing {
    width: 100%;
    height: 72vh;
    background: linear-gradient(0deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .5)), url('../../assets/gradiant10_06.jpg') no-repeat center center / cover;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: .3rem solid var(--primary-gold);
}

.content {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
}

.content-info {
    padding: 20px;
}

.content-image img {
    width: 600px;
    border-radius: 50% 17px 17px;
    border: .3rem solid var(--primary-gold)
}

.content-info h1 {
    color: var(--primary-light);
    padding: 20px;
    margin-top: 4rem;
    font-size: 2.4rem;
    text-shadow: 1px 0 0 var(--primary-gold);
}
.content-info .title {
    color: var(--primary-white);
    text-shadow: 1px 0 0 var(--light-white);
}
.content-info h2, .content-info p {
    color: var(--primary-light);
    padding-bottom: 12px;
}
.content-info button {
    color: var(--primary-blue);
}
.content-info button:hover {
    color: var(--light-white);
}

@media screen and (max-width: 940px) {
    .landing {
        height: 100%;
    }
    .content {
        flex-direction: column;
        margin-bottom: 3rem;
    }

    .content-image img {
        width: 74vw;
    }
    .content-info h1 {
        font-size: 2rem;
        font-weight: normal;
    }
    .content-info h2 {
        font-size: 1.95rem;
        font-weight: normal;
    }
}