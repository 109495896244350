.testimony {
    width: 100%;
    height: 75vh;
    background: url('../../../assets/bg-01.png') no-repeat center center / cover;
}

.testimony h1 {
    color: var(--light-white);
    padding: 2.4rem;
}

.testimony p {
    color: var(--light-white);
    padding-bottom: 1rem;
}

.testimony-content {
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-bottom: 2rem;
}

.testimony-content > div {
    background-color: var(--primary-white);
    border-radius: 12px;
    width: 300px;
    height: 350px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.testimony-one p, .testimony-one h3 {
    color: var(--primary-grey);
}

.icon {
    font-size: 2rem;
}

.testimony .testimo-active {
    height: 400px;
    background-color: var(--primary-blue);
    box-shadow: 0 8px 8px rgba(57, 49, 49, 0.5);
}

.testimony .testimo-active .icon {
    color: var(--light-white);
}
.testimony .testimo-active p {
    color: var(--light-white);
}
.testimony .testimo-active h3 {
    color: var(--light-white);
}

@media screen and (max-width: 940px) {
    .testimony {
        height: 100%;
    }
    .testimony-content {
        grid-template-columns: auto;
    }
}