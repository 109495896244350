.field-h1 {
    padding: 40px;
    color: var(--primary-blue);
}

.field-contents {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 7rem;
}

.field-contents > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1px solid var(--primary-blue);
    padding: 20px;
    border-radius: 12px;
    width: 250px;
    height: 150px;
    color: var(--primary-grey);
}

.field-card-one {
    background-color: var(--primary-blue);
}

.field-card-one .icon, .field-card-one .title, .field-card-one .rating {
    color: var(--light-white);
}

.icon {
    font-size: 2.4rem;
    color: var(--primary-blue);
}

.rating {
    font-size: 2rem;
    font-weight: 200;
}

@media screen and (max-width: 940px) {
    .field-contents {
        flex-direction: column;
    }
    .field-h1 {
        font-size: 2.4rem;
        padding-bottom: 10px;
    }
}