*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
  font-weight: 200;
}

:root {
  --primary-white: #fff;
  --primary-light: #f5f5f5;
  --light-white: #ccc;
  --primary-blue: #203095;
  --blue-light: #0fB8e0;
  --primary-grey: #808080;
  --primary-hover: #e5d256;
  --primary-black: #000;
  --primary-gold: #936C00;
}

/* Global Styles */
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}

p {
  font-size: 1.2rem;
  margin-bottom: 7px;
}
a {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: normal;
}

button {
  width: 150px;
  padding: 7px 14px;
  margin-bottom: 7px;
  border: none;
  border-radius: 7px;
  font-size: 1.2rem;
  transition: all .3 ease;
}
button:hover {
  background-color: transparent;
  border: 1px solid var(--primary-light);
  color: var(--light-white);
}

.container {
  position: relative;
  margin: 0 auto;
  width: 80%;
}

html, body {
  scroll-behavior: smooth;
  font-family: sans-serif;
}