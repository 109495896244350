.navbar {
    width: 100%;
    height: 100px;
    position: absolute;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 10px;
}

.navbar-logo img {
    width: 150px;
}
.navbar .navbar-menu ul li{
    display: inline-block;
}

.navbar .navbar-menu ul {
    list-style-type: none;
}
.navbar .navbar-menu ul li a {
    text-decoration: none;
    display: inline-block;
    padding-left: 12px;
    color: var(--primary-light);
    font-size: 1rem;
    transition: all .3s ease;
}

.navbar .navbar-menu ul li a:hover {
    transform: scale(1.01);
    color: var(--primary-hover);
}

.navbar-logo h1 {
    color: var(--primary-light);
}
.menu-bar {
    font-size: 1.8rem;
    color: var(--primary-light);
    display: none;
}

.navbar-menu button a {
    text-decoration: none;
    color: var(--primary-gray);
    font-size: 1.1rem;
}
.navbar-menu button {
    margin-left: 1rem;
    color: var(--primary-blue);
    background-color: var(--primary-light);
}

.navbar-menu button:hover {
    background-color: transparent;
    border: 1px solid var(--primary-light);
    color: var(--light-white);
}

@media screen and (max-width: 940px) {
    .navbar-logo img {
        width: 120px;
    }
    .menu-bar {
        display: block;
        cursor: pointer;
        transition: all .2s ease;
        z-index: 15;
    }
    .menu-bar:hover {
        transform: scale(1.2);
    }
    .navbar-menu {
        position: absolute;
        border-radius: .4rem;
        width: 100%;
        height: 65vh;
        top: 0;
        left: -113%;
        background: rgba(0, 0, 0, .85);
        transition: .5s ease-in;
    }
    .active {
        left: 0;
    }
    .navbar-menu ul li{
        width: 100%;
        padding: 3rem 0 2rem 0;
        
    }
    .navbar-menu button {
        margin-top: 1rem;
    }
}

